import { Box } from '@twilio-paste/core/box';
import { Button } from '@twilio-paste/core/button';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@twilio-paste/core/text';

import { sessionDataHandler } from '../sessionDataHandler';
import { addNotification, changeEngagementPhase, updatePreEngagementData } from '../store/actions/genericActions';
import { initSession } from '../store/actions/initActions';
import { AppState, EngagementPhase } from '../store/definitions';
import { Header } from './Header';
import { notifications } from '../notifications';
import { NotificationBar } from './NotificationBar';
import { introStyles, titleStyles, formStyles } from './styles/PreEngagementFormPhase.styles';

export const PreEngagementFormPhase = () => {
    const { label, country, language } = useSelector((state: AppState) => state.config.context) || {};
    const dispatch = useDispatch();
    const chatIcon = document.getElementById('UserFriendlyName')?.getAttribute('data-iconLink') ?? '';
    //const { onUserInputSubmit } = useSanitizer();

    const handleSubmit = async () => {
        const currentUserName = document.getElementById('UserFriendlyName')?.getAttribute('data') ?? '';
        const currentUserNumber = document.getElementById('UserFriendlyName')?.getAttribute('data-customerNumber') ?? '';
        localStorage.setItem('currentUserNumber', currentUserNumber);
        dispatch(
            updatePreEngagementData({
                friendlyName: currentUserName,
                customerNumber: currentUserNumber
            })
        )
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    label: label,
                    country: country,
                    language: language,
                    friendlyName: currentUserName,
                    customerNumber: currentUserNumber
                }
            });
            dispatch(
                initSession({
                    token: data.token,
                    conversationSid: data.conversationSid
                })
            );
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const maikoText = document.getElementById('UserFriendlyName')?.getAttribute('data-maikoText');
    const startChat = document.getElementById('UserFriendlyName')?.getAttribute('data-startChatText');
    const liveChat = document.getElementById('UserFriendlyName')?.getAttribute('data-liveChatText') ?? '';

    return (
        <>
            <Header customTitle={liveChat} />
            <NotificationBar />
            <Box as='form' data-test='pre-engagement-chat-form' onSubmit={handleSubmit} {...formStyles}>
                <div style={{position:'relative', marginBottom:'20px'}}>
                    <img 
                    src={chatIcon}
                    alt='Maiko' 
                    style={{borderRadius: '100%', height: '32px', width: '32px', marginBottom: '', float:'left', position:'absolute', bottom:'10px'}} 
                    />
                    <div style={{backgroundColor:'rgb(244, 244, 246)', marginLeft:'40px', borderRadius:'10%', paddingTop:'10px', paddingLeft:'10px', paddingBottom:'5px'}}>
                        <Text {...titleStyles} as='p' style={{float:'left', width:'10%', fontSize:'1em', fontWeight:'bold', marginBottom:'0px'}}>
                            Maiko
                        </Text>
                        <br />
                        <Text {...introStyles} as='p' style={{marginRight:'20px', marginBottom:'10px'}}>
                            { maikoText }
                        </Text>
                    </div>
                </div>

                <Button variant='primary' type='submit' data-test='pre-engagement-start-chat-button'>
                    { startChat }
                </Button>
            </Box>
        </>
        // <>
        //     <Flex hAlignContent='center' vAlignContent='center' height='100%'>
        //         <Spinner title='Authorizing' decorative={false} size='sizeIcon100' color='colorTextLink' />                
        //     </Flex>             
        // </>
    );
};