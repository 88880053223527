import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Key, KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
import { MinusIcon } from '@twilio-paste/icons/esm/MinusIcon';

import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import { set } from "cypress/types/lodash";

const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const [read, setRead] = useState(false);
    const [rating, setRating] = useState('');
    const [ratingSendingNow, setRatingSendingNow] = useState(false);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, externalLinkHandling, serverUrl } = useSelector((state: AppState) => ({
        conversationsClient: state.chat.conversationsClient,
        participants: state.chat.participants,
        users: state.chat.users,
        fileAttachmentConfig: state.config.fileAttachment,
        externalLinkHandling: state.config.context.externalLinkHandling,
        serverUrl: state.config.serverUrl
    }));
    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    const chatIcon = document.getElementById('UserFriendlyName')?.getAttribute('data-iconLink') ?? '';
    const chatFallbackIcon = document.getElementById('UserFriendlyName')?.getAttribute('data-fallbackIcon') ?? '';

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const rateMessage = async (rating: string) => {
        const payload = {
          conversationSid: message.conversation.sid,
          messageSid: message.sid,
          rating: rating,
        };

        if (ratingSendingNow) {
          return;
        }

        setRatingSendingNow(true);
        
        const helpfulText = messageRef.current?.querySelector('#helpfulText') as HTMLElement;
        const tnxForFeedbackText = messageRef.current?.querySelector('#tnxForFeedbackText') as HTMLElement;
        const rateBox = messageRef.current?.querySelector('#rateBox') as HTMLElement;
        
        if (helpfulText) {
            helpfulText.style.display = 'none';
        }
        if (tnxForFeedbackText) {
            tnxForFeedbackText.style.display = 'block';
        }

        setTimeout(() => { if (rateBox) { rateBox.style.display = 'none'; } }, 4000);

        var serverUrlForRating = serverUrl ? serverUrl + '/rate-message' : 'https://maiko.myworld.com/rate-message';

        try {
            const response = await fetch(serverUrlForRating, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            console.log(data);

            setRating(rating);
            
        } catch (error) {
            console.error('Error rating the message:', error);
        }
        localStorage.setItem(message.sid, 'true');
        setRatingSendingNow(false);
    };

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const author = users?.find((u) => u.identity === message.author)?.friendlyName || message.author;

    const helpful = document.getElementById('UserFriendlyName')?.getAttribute('data-helpful');
    const tnxForFeedback = document.getElementById('UserFriendlyName')?.getAttribute('data-tnxForFeedback');

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box {...getAvatarContainerStyles(!isLastOfUserGroup)} data-testid="avatar-container">
                        { author === 'Maiko' ? (
                            <img 
                            src={chatIcon} 
                            alt='Maiko'
                            style={{height:"32px", width:"32px"}}
                        />
                        ) : (
                            <img 
                            src={chatFallbackIcon} 
                            alt='myWorld'
                            style={{height:"32px", width:"32px"}}
                        />
                        )}
                        {/* {isLastOfUserGroup && <UserIcon decorative={true} size="sizeIcon40" />} */}
                    </Box>
                )}
                <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                        <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis" }} title={author}>
                            {author}
                        </Text>
                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p">
                            {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                message.dateCreated.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                    <Text as="p" {...bodyStyles}>
                        {message.body ? parseMessageBody(message.body, belongsToCurrentUser, externalLinkHandling) : null}
                    </Text>
                    {message.type === "media" ? renderMedia() : null}

                    {!belongsToCurrentUser && !localStorage.getItem(message.sid) && (
                        <div id="rateBox" style={{ color: 'grey', float: 'right' }}>
                            <Flex marginTop="space20">
                                    <p id="helpfulText" style={{ fontSize: '0.8em', marginTop: '6px' }}>{ helpful }</p>
                                    <p id="tnxForFeedbackText" style={{ display: 'none', fontSize: '0.8em', marginTop: '6px' }}>{ tnxForFeedback }</p>
                                    <i className="mwif-thumb-up mwif-1" title="Rate positive" id="rateButtonPlus" onClick={() => rateMessage('plus')} style={{ marginLeft: '4px', marginTop: '4px' }} />
                                    <i className="mwif-thumb-down mwif-1" title="Rate negative" id="rateButtonMinus" onClick={() => rateMessage('minus')} style={{ marginLeft: '4px', marginTop: '4px' }} />                                    
                            </Flex>
                        </div>
                    )}
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Read
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
