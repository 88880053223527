import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useDispatch } from "react-redux";

import { sessionDataHandler } from "../sessionDataHandler";
import { changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";

export const ConversationEnded = () => {
    const dispatch = useDispatch();
    const handleStartNewChat = () => {
        sessionDataHandler.clear();
        dispatch(updatePreEngagementData({ friendlyName: "" }));
        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
    };
    const thanksForChatting = document.getElementById('UserFriendlyName')?.getAttribute('data-endTextOne');
    const questions = document.getElementById('UserFriendlyName')?.getAttribute('data-endTextTwo');
    const startNewChat = document.getElementById('UserFriendlyName')?.getAttribute('data-startNewChatText');

    return (
        <Box {...containerStyles}>
            <Text as="h3" {...titleStyles}>
                { thanksForChatting }
            </Text>
            <Text as="p" {...textStyles}>
                { questions }
            </Text>
            <Button variant="primary" data-test="start-new-chat-button" onClick={handleStartNewChat}>
                { startNewChat }
            </Button>
        </Box>
    );
};
