const LINK_CUSTOME_EVENT_NAME = "MaikoLinkClicked";
const VIDEO_CUSTOME_EVENT_NAME = "MaikoVideoPreviewClicked";

export const notifyLinkClicked = (link: string): void => {
    // Trigger the event
    var event = new CustomEvent(LINK_CUSTOME_EVENT_NAME, {
        detail: { link }
    });
    dispatchCustomEvent(event);
};

export const notifyVideoPreviewClicked = (link: string): void => {

    // Trigger the event
    var event = new CustomEvent(VIDEO_CUSTOME_EVENT_NAME, {
        detail: { link }
    });

    dispatchCustomEvent(event);
};

const dispatchCustomEvent = (event: CustomEvent): void => {
    const element = getEventRootElement();
    element?.dispatchEvent(event);
}

const getEventRootElement = (): HTMLElement | null => {
    var element = document.getElementById('twilio-webchat-widget-root');
    return element;
}